<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab active>
        <template #title>
          <b-img
            class="miniMenu"
          :src="config.VUE_APP_WP_ASSETS+'assets/grid-white.png'"
          ></b-img>
          <span class="miniMenuTitle"> {{ wpPage.title.rendered }} </span>
        </template>
        <div class="content grey">
          <b-row class="topRow">
            <b-col :class="{ 'col-lg-10': wpPage.acf.enable_sidebar === true }">
              <Background :img="banner()" :title="wpPage.title.rendered" />
              <div :class="{ container: page === 'OpenPlay' }">
                <span
                  class="my-content"
                  v-html="wpPage.content.rendered"
                ></span>
              </div>
              <div v-if="page === 'Handicapping' || page === 'Club Support'">
                <b-row
                  v-for="(topList, index) in wpPageChild.slice().reverse()"
                  :key="index"
                >
                  <b-col class="myPost">
                    <div class="myBox">
                      <div
                        class="myFlex myImg"
                        :style="
                          'background-image:url(' +
                            topList.better_featured_image.source_url +
                            ');'
                        "
                      ></div>
                      <div class="myFlex myTitle">
                        <div class="myText">
                          <h3>
                            {{ topList.title.rendered }}
                          </h3>

                          <span v-html="topList.excerpt.rendered"></span>
                        </div>
                      </div>
                      <div class="myFlex myLinks">
                        <b-button @click="tabIndex = index + 1" class="linkGrey"
                          >More Info</b-button
                        >
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <template v-if="wpPageChild.length !== 0">
        <b-tab
          v-for="(tab, index) in wpPageChild.slice(3, 12).reverse()"
          :key="index"
        >
          <template #title>
            <span class="miniMenuTitle" v-html="tab.title.rendered"></span>
          </template>

          <div class="content grey">
            <b-row class="topRow">
              <b-col :class="{ 'col-lg-10': tab.acf.enable_sidebar === true }">
                <Background
                  :img="tab.acf.banner_image"
                  :title="tab.title.rendered"
                />
                <div :class="{ container: page === 'OpenPlay' }">
                  <span class="my-content" v-html="tab.content.rendered"></span>
                  <CustomList
                    :customList="tab.acf.custom_list"
                    type="customList"
                    :config="config"
                  />
                  <CustomList :customList="tab.acf.partner" type="partners" :config="config"/>
                </div>
              </b-col>
            </b-row>
          </div> </b-tab
      ></template>
      <template #tabs-end>
        <b-nav-item :to="{
                name: 'Official Partners',
                query: {
                  id: 451,
                },
              }" role="presentation">Offical Partners</b-nav-item>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import Background from "@/components/backgroundSplash.vue";
import CustomList from "@/components/customList.vue";
import apiCall from "@/services/api.js";
export default {
  name: "aboutUs",
  components: { Background, CustomList },
  props:['config'],
  data() {
    return {
      wpPage: [],
      wpPageChild: [],
      page: this.$route.name,
      tabIndex: 0,
    };
  },
  methods: {
    banner: function(ban) {
      if ("banner_image" in this.wpPage.acf) ban = this.wpPage.acf.banner_image;
      else ban = false;
      return ban;
    },
  },
      async created() {
    var one = "wp/v2/pages/" + this.$route.query.id;
    var two = "wp/v2/pages?parent=" + this.$route.query.id + "&per_page=100";
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(one);
      this.wpPage = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(two);
      this.wpPageChild = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
::v-deep .nav-tabs {
  background-color: #8ac2e6;
}
::v-deep .nav-tabs > .nav-item {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .nav-tabs > .nav-item > .nav-link {
  color: #0071b9;
  padding: 0.5rem 1.2rem 0.5rem 0rem;
}
::v-deep .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
  color: #fff;
}
::v-deep .nav-tabs > .nav-item > .nav-link.active {
  background-color: transparent;
  border: 0;
  color: white;
}
::v-deep .nav-tabs > .nav-item:first-child {
  color: white;
  padding: .5rem 3rem .5rem 1rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: #0071b9;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .nav-tabs > .nav-item:first-child > .nav-link {
  color: #fff;
}
.miniMenu {
  height: 18px;
  width: auto;
}
.miniMenuTitle {
  margin-left: 1rem;
}
.topRow {
  padding: 0;
  margin: 0;
}
.content {
  padding: 1rem 0;
}
::v-deep .wp-block-button {
  color: #fff;
  margin-bottom: 1.5em;
}
::v-deep .wp-block-button__link {
  background-color: #32373c;
  border: none;
  border-radius: 28px;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin: 0;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;
}
::v-deep
  .has-vivid-cyan-blue-background-color.has-vivid-cyan-blue-background-color {
  background-color: #0693e3;
}

::v-deep hr {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  border-top: solid 1px #e5e5e5;
}
::v-deep .my-content > h2 {
  margin-bottom: 1.5rem !important;
  font-size: 20px;

  color: #0071b9;
}
::v-deep .wp-block-media-text {
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  grid-template-areas: "media-text-media media-text-content";
  grid-template-columns: 50% auto;
}
::v-deep .wp-block-media-text .wp-block-media-text__media {
  grid-area: media-text-media;
  margin: 0;
}
::v-deep .wp-block-media-text .wp-block-media-text__content {
  word-break: break-word;
  grid-area: media-text-content;
  padding: 0 8%;
}
::v-deep .wp-block-media-text__content > h1 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #0071b9;
}
::v-deep
  figure.wp-block-embed-youtube.wp-block-embed.is-type-video.is-provider-youtube.wp-embed-aspect-16-9.wp-has-aspect-ratio.youtubeOpenPlay
  > div
  > iframe {
  width: 100%;
  height: 700px !important;
}
::v-deep .wp-block-image {
  max-width: 100%;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}
::v-deep .wp-block-image .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
::v-deep .wp-block-columns {
  display: flex;
  flex-wrap: wrap;
}
::v-deep .wp-block-image img {
  max-width: 100%;
}
::v-deep figure {
  margin: 0 0 1rem;
}
::v-deep .wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.is-resized {
  display: table;
  margin-left: 0;
  margin-right: 0;
}
::v-deep .wp-block-column {
  flex-grow: 1;
  margin-bottom: 1em;
  flex-basis: 100%;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}
::v-deep .my-content > h1 {
  color: #0071b9;
}
::v-deep .wp-block-button.aligncenter {
  text-align: center;
}
::v-deep .my-content img {
  width: 100%;
  height: auto;
}
::v-deep .wp-block-image.is-resized {
  display: table;
  margin-left: 0;
  margin-right: 0;
}
.myPost {
  margin-bottom: 1rem;
  display: flex;
}
.myBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-self: unset;
  background-color: white;
  border: solid 1px #e5e5e5;
  width: 100%;
}
.myFlex.myImg {
  background-position: center;
  background-size: cover;
  padding-bottom: 40%;
}
.myText > h3 {
  font-size: 16px;
  color: #0071b9;
}
.grey {
  background-color: #f2f2f2;
}

.myFlex {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.myFlex.myTitle {
  padding: 0 1rem;
  flex: 1;
  flex-grow: 1;
}
.linkGrey {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  padding: 0.375rem 3rem;
}
@media (min-width: 782px) {
  ::v-deep .wp-block-columns {
    flex-wrap: nowrap;
  }
  ::v-deep .wp-block-column:not(:first-child) {
    margin-left: 32px;
  }
}
@media (min-width: 600px) {
  ::v-deep .wp-block-column {
    flex-basis: calc(50% - 16px);
    flex-grow: 0;
  }
}
@media (min-width: 992px) {
  .myBox .myFlex.myImg {
    padding-bottom: 0;
    flex-basis: 30%;
    clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 0 100%);
  }
  .myFlex.myLinks {
    margin-top: 0;
    flex-basis: auto;
    background-color: #8ac2e6;
    padding: 3.5rem 2rem 3.5rem 6rem;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4rem 100%);
  }
  .myFlex.myTitle {
    padding: 0 1rem;
    flex: 1;
    flex-grow: 1;
  }
}
@media (min-width: 1200px) {
  .myFlex.myLinks {
    flex-direction: row;
  }
}
</style>
