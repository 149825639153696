<template>
  <div>
    <h2 class="topTitle">
      {{title}}
    </h2>
    <b-row>
      <b-col lg="4" md="12">
        <b-form-group id="input-serach" label="Name" label-for="search">
          <b-input-group size="md">
            <b-form-input
              id="search"
              v-model="filter"
              type="search"
              placeholder="Event or Club Name"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="4" md="6">
        <b-form-group id="input-gender" label="Gender" label-for="gender">
          <b-form-select
            id="gender"
            v-model="filter"
            :options="genderOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="4" md="6">
        <b-form-group id="input-orderby" label="Order by" label-for="orderby">
          <b-form-select
            id="orderby"
            v-model="orderFilter"
            :options="orderBy"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="btnRow">
      <b-button class='fpBtn' :class="{active : this.active === true}" @click="futureTours()">Future</b-button><b-button class="fpBtn" @click="pastTours()" :class="{active : this.active === false}">Past</b-button>
    </div>
    <Table :indexData="indexData" :filteredList="filteredList" :title="title" :config="config"/>

  </div>
</template>

<script>

import axios from "axios";
import Table from '@/components/table.vue'
import _ from 'lodash';

export default {
  name: "eventsTable",
  components:{Table},
  props:['url', 'title', 'config'],
  data() {
    return {
        active:true,
      genderOptions: [
        { value: "", text: "All" },
        { value: "MIX", text: "Mixed" },
        { value: "MAL", text: "Male" },
        { value: "FEM", text: "Female" },
      ],
      orderBy: [
        { value: "", text: "Default" },
        { value: "short_name", text: "Name" },
        { value: "class", text: "Gender" },
        { value: "check_age_desc", text: "Age" },
      ],
      filter: '',
      orderFilter:'',
      indexData: [],
      dataSet: this.filteredList
    };
  },
  computed:{
      filteredList: function(sorted){
          var unSorted = this.indexData.tournaments.filter(tours => {
              return tours.data.class.includes(this.filter) || tours.data.short_name.includes(this.filter) || tours.data.course.includes(this.filter)
          })
        if (this.orderFilter === 'short_name')
        sorted = _.sortBy(unSorted, ['short_name'])
        else if (this.orderFilter === 'class')
        sorted = _.sortBy(unSorted, ['class'])
        else if (this.orderFilter === 'check_age_desc')
        sorted = _.sortBy(unSorted, ['check_age_desc'])
        else sorted = unSorted
        return sorted
      },
  },
  methods: {
    futureTours(){
        return(
            (this.active = true),
        axios
          .get(this.url)
          .then((response) => (this.indexData = response.data))
        );
    },
    pastTours(){
        return(
            (this.active = false),
        axios
          .get(this.url + "?past=y")
          .then((response) => (this.indexData = response.data))
        );
    }
  },
  mounted() {
    axios //add API Call
      .get(this.url) // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.indexData = response.data)); //API data wanted pulled out
  },
};
</script>

<style scoped>

.topTitle {
  margin-bottom: 1.5rem;
  font-size: 20px;
  color: #0071b9;
  line-height: 1.2;
}
.btnRow {
  padding-left: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-bottom: 10px;
}
.fpBtn{
  color: #fff;
  background-color: #9bcbea;
  cursor: pointer;
  border: 0;
  border-radius: 0;
}
.fpBtn:first-child{
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  padding: 1rem 3rem 1rem 1.5rem;
    
}
.fpBtn:nth-child(2){
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
.fpBtn.active{
  background-color: #0071b9 !important;
}
</style>
