<template>
  <div>
    <div class="choose">
      <div class="title">
        <h2 class="text">
          Past Champions
        </h2>
      </div>
      <div class="pick">
        <b-form-group
          id="input-Tournaments"
          label="Tournaments"
          label-for="Tournaments"
        >
          <b-form-select
            id="Tournaments"
            v-model="filter"
            :options="indexData.tourns"
            text-field="title"
            value-field="title"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>
    <div>
      <b-tabs class="smallNav">
        <b-tab :title="filteredList[0].title" active>
          <div class="table-responsive">
            <table>
              <thead>
                <tr>
                  <th
                    v-for="(head, index) in filteredList[0].headers"
                    :key="index"
                  >
                    {{ head }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bod, index) in filteredList[0].years" :key="index">
                  <template v-if="typeof bod.year === 'string'">
                    <td
                      colspan="100"
                      style="text-align:center; background-color: #e5e5e5 !important; text-transform: uppercase; text-align: center; color: #737373 !important;"
                    >
                      {{ bod.year }}
                    </td>
                  </template>
                  <template v-else>
                    <td :class="{ hide: bod.year === undefined }">
                      {{ bod.year }}
                    </td>
                    <td :class="{ hide: bod.venue === undefined }">
                      {{ bod.venue }}
                    </td>
                    <td :class="{ hide: bod.winner === undefined }">
                      {{ bod.winner }}
                    </td>
                    <td :class="{ hide: bod.runner_up === undefined }">
                      {{ bod.runner_up }}
                    </td>
                    <td :class="{ hide: bod.result === undefined }">
                      {{ bod.result }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "pastChamps",
  data() {
    return {
      indexData: [],
      filter: "",
    };
  },
  computed: {
    lastYear(today) {
      today = new Date();
      return today.getFullYear() - 2;
    },
    filteredList: function() {
      var list = this.indexData.tourns.filter((tours) => {
        return tours.title.includes(this.filter);
      });
      return list;
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WP_ASSETS+"assets/data/past-champions-" +
          this.lastYear +
          ".json?randomadd=" +
          new Date().getTime()
      )
      .then(
        (response) => (
          (this.indexData = response.data),
          (this.filter = response.data.tourns[0].title)
        )
      ); //API data wanted pulled out
  },
};
</script>

<style scoped>
.choose {
  background-color: white;
  margin-bottom: 2rem;
  display: block;
  border: solid 1px #e5e5e5;
}
.title {
  background-image: url(https://scottishgolf.org/wp-content/themes/scottishgolf/assets/temp/temp-tree.jpg);
  padding: 3rem 2rem;
  background-position: center;
  background-size: cover;
}
.text {
  color: white;
  margin-bottom: 0;
  font-size: 20px;
}
.pick {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
::v-deep #input-Tournaments > label {
  color: #0071b9;
  margin-bottom: 0.5rem;
}
::v-deep thead > tr {
  background-color: #0071b9;
  color: white;
}
::v-deep th {
  padding: 0.75rem 1rem;
  border: none !important;
}
::v-deep td {
  padding: 0.75rem 1rem;
  border: none !important;
}
::v-deep table {
  width: 100%;
}
.hide {
  display: none;
}
::v-deep tbody > tr:nth-of-type(odd) {
  background-color: white;
}
::v-deep tbody > tr:nth-of-type(even) {
  background-color: #f3f9fc;
}
@media (min-width: 992px) {
  .title {
    white-space: nowrap;
    padding: 3rem 6rem 3rem 2rem;
    clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 0 100%);
  }
  .choose {
    display: flex;
  }
  .text {
    margin-right: 3rem;
  }
}
</style>
