<template>
  <div>
    <b-navbar toggleable="lg" type="dark" class="nav-tabs noPad mobNav">
      <b-navbar-toggle target="nav-collapse" style="border:0; ">
        <b-img
          class="miniMenu"
          :src="config.VUE_APP_WP_ASSETS + 'assets/grid-white.png'"
        ></b-img>
        <span style="color:#fff; padding-left:10px; vertical-align: middle;"
          >Menu</span
        ></b-navbar-toggle
      >
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <span
            v-for="(tab, index) in wpPageChild.slice().reverse()"
            :key="index"
          >
            <b-button
              @click="tabIndex = index + 1"
              v-html="tab.title.rendered"
              class="mobNav mobBtn"
            ></b-button>
          </span>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-tabs v-model="tabIndex" class="bigNav mob">
      <b-tab active>
        <template #title>
          <b-img
            class="miniMenu"
            :src="config.VUE_APP_WP_ASSETS + 'assets/grid-white.png'"
          ></b-img>
          <span class="miniMenuTitle"> {{ wpPage.title.rendered }} </span>
        </template>
        <div class="content grey">
          <b-row class="topRow ">
            <b-col>
              <b-tabs class="smallNav" content-class="mt-3 ">
                <b-tab title="National Events" active>
                  <div class="feature">
                    <NationalEvents :config="config" />
                  </div>
                  <EventsTable
                    :config="config"
                    :url="
                      config.VUE_APP_TIC_BASE +
                        config.VUE_APP_CLUB_ID +
                        '/cache/TOUR/index'
                    "
                    title="All Events"
                  />
                </b-tab>
                <b-tab title="Open Events">
                  <EventsTable
                    :config="config"
                    :url="
                      config.VUE_APP_TIC_BASE +
                        config.VUE_APP_MASTER_ID +
                        '/cache/TOUR/index'
                    "
                    title="Open Events"
                  />
                </b-tab>
                <b-tab title="Social Events"><p>No Current Data</p></b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <template v-if="wpPageChild.length !== 0">
        <b-tab
          v-for="(tab, index) in wpPageChild.slice(0, 2).reverse()"
          :key="index"
        >
          <template #title>
            <span class="miniMenuTitle" v-html="tab.title.rendered"></span>
          </template>
          <div class="content grey">
            <b-row class="topRow ">
              <b-col>
                <div v-if="tabIndex === 1">
                  <BackgroundSplash
                    :title="tab.title.rendered"
                    :img="tab.acf.banner_image"
                  />
                  <b-row>
                    <b-col
                      lg="6"
                      v-for="(pol, index) in tab.acf.policy"
                      :key="index"
                      class="post"
                    >
                      <div class="polContent">
                        <div class="myCol">
                          <span>{{ pol.title }}</span>
                        </div>
                        <div class="myColAuto">
                          <b-button :href="pol.document" target="_blank"
                            >View</b-button
                          >
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="tabIndex === 2">
                  <PastChamp />
                </div>
              </b-col>
            </b-row></div></b-tab
      ></template>
    </b-tabs>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import NationalEvents from "@/components/nationalEvents.vue";
import EventsTable from "@/components/eventsTable.vue";
import BackgroundSplash from "@/components/backgroundSplash.vue";
import PastChamp from "@/components/pastChamp.vue";
export default {
  name: "Events",
  components: { NationalEvents, EventsTable, BackgroundSplash, PastChamp },
  props: ["config"],
  data() {
    return {
      wpPage: [],
      wpPageChild: [],
      page: this.$route.name,
      tabIndex: 0,
    };
  },
  methods: {
    banner: function(ban) {
      if (this.wpPage.acf === false) ban = "noBanner";
      else if ("banner_image" in this.wpPage.acf)
        ban = this.wpPage.acf.banner_image;
      else ban = false;
      return ban;
    },
  },
  async created() {
    var one = "wp/v2/pages/" + this.$route.query.id;
    var two = "wp/v2/pages?parent=" + this.$route.query.id + "&per_page=100";
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(one);
      this.wpPage = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(two);
      this.wpPageChild = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
.mobNav {
  display: none;
}
::v-deep .nav-tabs {
  background-color: #8ac2e6;
}
::v-deep .smallNav > div > .nav-tabs {
  background-color: #f2f2f2;
  border-bottom: 0;
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: #0071b9 !important;
}
::v-deep
  .smallNav
  > div
  > .nav-tabs
  > .nav-item:nth-child(10n + 3)
  > .nav-link {
  background-color: #92c6e8;
}
::v-deep
  ::v-deep
  .smallNav
  > div
  > .nav-tabs
  > .nav-item:nth-child(10n + 2)
  > .nav-link {
  background-color: #9bcbea;
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  background-color: #8ac2e6;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  background-color: #8ac2e6;
  color: #fff;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item:first-child {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:first-child > .nav-link {
  color: #fff;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item {
  background-color: #8ac2e6;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item > .nav-link {
  color: #0071b9;
}
::v-deep .bigNav > div > .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
  color: #fff;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: transparent;
  border: 0;
  color: white;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:first-child {
  color: white;
  padding: 0.5rem 3rem 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: #0071b9;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:not(:first-child) {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
.miniMenu {
  height: 18px;
  width: auto;
}
.miniMenuTitle {
  margin-left: 1rem;
}
.topRow {
  padding: 0;
  margin: 0;
}
.content {
  padding: 1rem 1rem;
}
.grey {
  background-color: #f2f2f2;
}
.feature {
  margin-bottom: 2rem;
}
.polContent {
  background-color: white;
  width: 100%;
  padding: 1.5rem;
  border: solid 1px #e5e5e5;
  overflow-wrap: break-word;
}
@media (min-width: 768px) {
  .polContent {
    padding: 3rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .myCol {
    margin-bottom: 0;
    flex-grow: 1;
    flex-basis: auto;
    margin-right: 1rem;
  }
  .myColAuto {
    flex-grow: 0;
    flex-basis: auto;
  }
  .myColAuto a {
    width: auto;
    padding: 0.375rem 3rem;
    background-color: #0071b9;
    border-color: #0071b9;
  }
  .content {
    padding: 1rem 0;
  }
}
.post {
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .desktop {
    display: none;
  }
  .mobNav {
    list-style-type: none;
    border-bottom: solid 1px #75b7e2;
    padding: 1rem;
    display: block;
    text-decoration: none;
    color: white !important;
  }
  .navbar-toggler {
    overflow-anchor: none;
    border: 0px;
    color: white;
    padding: 1rem 3rem 1rem 1rem;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    background-color: #0071b9;
    position: relative;
    clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  }
  .mobBtn {
    background-color: transparent;
    border: 0;
  }
  .mobBtn:active {
    background-color: transparent;
    border: 0;
  }
  .mobBtn:focus {
    background-color: transparent;
    border: 0;
  }
  .noPad {
    padding: 0;
  }
  ::v-deep .mob > div > ul {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep .smallNav > div > .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    max-width: 700px;
    overflow: auto;
  }
}
</style>
