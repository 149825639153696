<template>
    <div class="partLogo" :style="'background-image: url(' + logoUrl + ');'"></div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
    name:'logo',
    props:['logo'],data(){
        return{
            logoUrl:''
        }
    },
              created(){
   var json = "wp/v2/media/" + this.logo
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.logoUrl = data.source_url;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
}
</script>

<style scoped>
.partLogo {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>