<template>
  <div>
    <div
      class="table desktop"
      v-for="(tours, index) in filteredList"
      :key="index"
    >
      <div class="tableImg flex">
        <template v-if="title === 'Open Events'">
          <div class="myVenue">
            {{ tours.data.course_venue }}
          </div>
          <div
            class="myVenueLogo"
            :style="
              'background-image: url('+ config.VUE_APP_VMS_DIGITAL_OCEAN +
                tours.data.venue_logo +
                ');'
            "
          ></div>
        </template>
      </div>
      <div class="flex date">
        <div>
          <div>{{ tours.data.dates }}</div>
          <small v-if="title === 'All Events'"
            >Entries Close: {{ tours.data.closing_date }}
            {{ tours.data.closing_time }}</small
          >
          <div v-if="title === 'All Events'">
            <span class="badge badgeWarning" v-if="isClosed(tours) === false"
              >Entries Closed
            </span>
          </div>
        </div>
      </div>
      <div class="flex details">
        <div>
          <h2 class="tourName">{{ tours.data.short_name }}</h2>
          <h3>
            <span class="blue">{{ tours.data.course }}</span>
          </h3>
          <div class="detailsFlex">
            <div class="flexItem">
              <small>
                Rounds
              
              <div>
                {{ tours.data.rounds }}
              </div>
              </small>
            </div>
            <div class="flexItem">
              <small>
                Tee
              
              <div>
                <span class="tee" :class="tours.data.tee_colour"></span>
              </div>
              </small>
            </div>
            <div class="flexItem">
              <small>
                Fee
              
              <div>£{{ tours.data.basic_fee }}</div>
              </small>
            </div>
            <span v-if="tours.data.check_age_desc === ''"></span>
            <div v-else class="flexItem">
              <small>
                Age
              
              <div>
                {{ tours.data.check_age_desc }}
              </div>
              </small>
            </div>
            <div class="flexItem">
              <small>
                Gender
              
              <div>
                <span v-if="tours.data.t_image_1 === ''"></span>
                <span v-else>
                  <font-awesome-icon
                    v-if="tours.data.t_image_1 === 'ion-man'"
                    id="fb"
                    class="icon male"
                    :icon="['fa', 'male']"
                  /><font-awesome-icon
                    v-if="tours.data.t_image_1 === 'ion-woman'"
                    id="fb"
                    class="icon female"
                    :icon="['fa', 'female']"
                  />
                </span>
                <span v-if="tours.data.t_image_2 === ''"></span>
                <span v-else>
                  <font-awesome-icon
                    v-if="tours.data.t_image_2 === 'ion-man'"
                    id="fb"
                    class="icon male"
                    :icon="['fa', 'male']"
                  /><font-awesome-icon
                    v-if="tours.data.t_image_2 === 'ion-woman'"
                    id="fb"
                    class="icon female"
                    :icon="['fa', 'female']"
                  />
                </span>
              </div>
              </small>
            </div>
            <span v-if="tours.data.check_hcap_desc === ''"></span>
            <div v-else class="flexItem">
              <small>
                H/Cap
              
              <div>
                {{ tours.data.check_hcap_desc }}
              </div>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="flex links">
        <b-button
          :to="{
            name: 'competition',
            query: {
              id: tours.data.code,
            },
          }"
          class="moreBtn"
          >More Info
        </b-button>
      </div>
    </div>
    <div class="mob">
      <b-row>
        <b-col md="6" v-for="(tours, index) in filteredList" :key="index">
          <b-card
            :img-src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/01/DEPARMENT-HEADER-IMAGE-V1-300x111.png'"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
            <b-card-text>
              <div class="flex date">
                <div>
                  <div>{{ tours.data.dates }}</div>
                  <small v-if="title === 'All Events'"
                    >Entries Close: {{ tours.data.closing_date }}
                    {{ tours.data.closing_time }}</small
                  >
                  <span v-if="title === 'All Events'" style="padding-left: 5px;">
                    <span
                      class="badge badgeWarning"
                      v-if="isClosed(tours) === false"
                      >Entries Closed
                    </span>
                  </span>
                </div>
              </div>
              <div class="flex details">
                <div>
                  <h2 class="tourName">{{ tours.data.short_name }}</h2>
                  <h3>
                    <span class="blue">{{ tours.data.course }}</span>
                  </h3>
                  <div class="detailsFlex">
                    <div class="flexItem">
                      <small>
                        Rounds
                      
                      <div>
                        {{ tours.data.rounds }}
                      </div>
                      </small>
                    </div>
                    <div class="flexItem">
                      <small>
                        Tee
                      
                      <div>
                        <span class="tee" :class="tours.data.tee_colour"></span>
                      </div>
                      </small>
                    </div>
                    <div class="flexItem">
                      <small>
                        Fee
                      
                      <div>£{{ tours.data.basic_fee }}</div>
                      </small>
                    </div>
                    <span v-if="tours.data.check_age_desc === ''"></span>
                    <div v-else class="flexItem">
                      <small>
                        Age
                      
                      <div>
                        {{ tours.data.check_age_desc }}
                      </div>
                      </small>
                    </div>
                    <div class="flexItem">
                      <small>
                        Gender
                      
                      <div>
                        <span v-if="tours.data.t_image_1 === ''"></span>
                        <span v-else>
                          <font-awesome-icon
                            v-if="tours.data.t_image_1 === 'ion-man'"
                            id="fb"
                            class="icon male"
                            :icon="['fa', 'male']"
                          /><font-awesome-icon
                            v-if="tours.data.t_image_1 === 'ion-woman'"
                            id="fb"
                            class="icon female"
                            :icon="['fa', 'female']"
                          />
                        </span>
                        <span v-if="tours.data.t_image_2 === ''"></span>
                        <span v-else>
                          <font-awesome-icon
                            v-if="tours.data.t_image_2 === 'ion-man'"
                            id="fb"
                            class="icon male"
                            :icon="['fa', 'male']"
                          /><font-awesome-icon
                            v-if="tours.data.t_image_2 === 'ion-woman'"
                            id="fb"
                            class="icon female"
                            :icon="['fa', 'female']"
                          />
                        </span>
                      </div>
                      </small>
                    </div>
                    <span v-if="tours.data.check_hcap_desc === ''"></span>
                    <div v-else class="flexItem">
                      <small>
                        H/Cap
                      
                      <div>
                        {{ tours.data.check_hcap_desc }}
                      </div>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-text>
            <div class="flex links">
              <b-button
                :to="{
                  name: 'competition',
                  query: {
                    id: tours.data.code,
                  },
                }"
                class="moreBtn"
                >More Info
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "table",
  props: ["indexData", "filteredList", "title", 'config'],
  methods: {
    isClosed(tours) {
      var cd = tours.data.closing_date;
      var ct = tours.data.closing_time;
      var closeDate = new Date(cd).getTime(ct);
      var todayDate = new Date().getTime();

      return todayDate >= closeDate;
    },
  },
};
</script>

<style scoped>
.table {
  display: flex;
  background-color: #fff;
}
.mob {
  display: none;
}
.content {
  cursor: pointer;
  background-color: white;
  border: solid 1px #e5e5e5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-self: stretch;
}
@media (min-width: 992px) {
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    align-self: unset;
  }
  .flex {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  .flex.date {
    text-align: center;
    flex-basis: 20%;
    background-color: #0071b9;
    color: white;
    justify-content: center;
    padding: 0 4rem!important;
    clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 4rem 100%);
    -webkit-clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 4rem 100%);
    margin-left: -4rem;
  }
  .flex.details {
    flex: 1;
    flex-grow: 1;
    padding: 0.5rem;
  }
  .flex.details .detailsFlex .flexItem {
    border-right: solid 1px #e5e5e5;
    margin-right: 1rem;
  }
  .flex.links {
    display: flex;
    align-items: center;
    margin-top: 0;
    background-color: #8ac2e6;
    padding: 3rem 2rem 3rem 6rem;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 4rem 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 4rem 100%);
  }
  .myVenue {
    align-self: flex-start;
    flex-direction: column;
  }
}
.flex.details .detailsFlex .flexItem {
  padding-right: 1rem;
}
.flex.details {
  padding: 0.5rem 0rem;
  background-color: #fff;
}
.flex.tableImg {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sg/2020/01/DEPARMENT-HEADER-IMAGE-V1-300x111.png);
  padding-bottom: 0;
  padding-right: 4rem;
  flex-basis: 20%;
  clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 4rem) 0, 100% 100%, 0 100%);
  background-position: center;
  background-size: cover;
  position: relative;
}
.flex.date {
  padding: 1rem;
  padding-bottom: 0;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badgeWarning {
  color: #212529;
  background-color: #ffc107;
}
.blue {
  color: #8ac2e6;
  font-size: 16px;
}
.tourName {
  color: #0071b9;
  font-size: 20px;
}
.item {
  padding-right: 1.25rem;
  border-right: solid 1px rgba(255, 255, 255, 0.1);
  margin-right: 1.25rem;
}
.flex.details .detailsFlex {
  display: flex;
  justify-content: flex-start;
  text-align: center;
}
.tee {
  height: 0.75rem;
  width: 0.75rem;
  display: inline-block;
  border: solid 1px #e5e5e5;
  border-radius: 50px;
}
.W {
  background-color: #fff;
}
.R {
  background-color: #f00;
}
.B {
  background-color: #000;
}
.male {
  color: dodgerblue;
}
.female {
  color: deeppink;
}
.myVenue {
  color: white !important;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}
.myVenueLogo {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  background-size: contain;
  background-position: bottom left;
  height: 40%;
  width: 100%;
  background-repeat: no-repeat;
}
@media (min-width: 1440px) {
  .flex.links {
    flex-direction: row;
  }
}
.moreBtn {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  padding: 0.375rem 3rem;
}
@media (max-width: 768px) {
  .desktop {
    display: none;
  }
  .mob {
    display: block;
  }
  small, .small {
    font-size: 80%;
    font-weight: 400;
}
.flex.details .detailsFlex .flexItem {
    padding-right: .5rem;
}
.flex.details {
    padding: 0.5rem 0;
}
.flex.date {
    padding: 1rem 0;
    padding-bottom: 0;
}
}
</style>
