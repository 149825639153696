<template>
  <b-tabs v-model="tabIndex">
    <b-tab>
      <template #title>
        <b-img
          class="miniMenu"
          :src="config.VUE_APP_WP_ASSETS + 'assets/grid-white.png'"
        ></b-img>
        <span class="miniMenuTitle">Login</span>
      </template>
      <div>
        <b-row class="topRow my-login">
          <b-col lg="6">
            <b-container class="content">
              <template v-if="tab === 'login' || tab === 'cdh'">
                <b-tabs class="smallNav">
                  <b-tab @click="tab = 'login'" title="Player Login" active>
                    <div class="login">
                      <b-alert
                        variant="success"
                        show
                        style="margin-bottom: 3rem;"
                      >
                        <span
                          >Welcome to the new home of Scottish Golf. If this is
                          your first time logging in on the new website and have
                          not already registered through our app, you will be
                          required to register your details. To do this, please
                          click <strong>Register</strong> below and fill out the
                          form.</span
                        >
                      </b-alert>
                    </div>
                  </b-tab>
                  <b-tab @click="tab = 'cdh'" title="Club CDH Login"> </b-tab>
                </b-tabs>
              </template>
              <template v-if="tab === 'login' || tab === 'register'">
                <b-tabs class="smallNav bottomTabs">
                  <b-tab @click="tab = 'login'" title="Login" active>
                    <div class="login">
                      <h1 class="title">
                        Login to Scottish Golf
                      </h1>
                      <b-form>
                        <b-form-group id="input-group-1" label-for="input-1">
                          <b-form-input
                            id="input-1"
                            v-model="form.username"
                            type="text"
                            placeholder="Username"
                            required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2">
                          <b-form-input
                            id="input-2"
                            type="password"
                            v-model="form.password"
                            placeholder="Password"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-row>
                          <b-col>
                              <span class='forPass'>Forgotten your password?</span>
                          </b-col>
                          <b-col>
                            <b-button class='loginBtn' type="submit">Login</b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </div>
                  </b-tab>
                  <b-tab @click="tab = 'register'" title="Register"> </b-tab>
                </b-tabs>
              </template>
            </b-container>
          </b-col>

          <b-col
            lg="6"
            class="my-brand"
            :style="
              'background-image:url(' +
                config.VUE_APP_WP_DIGITAL_OCEAN +
                '2020/01/DEPARMENT-HEADER-IMAGE-V1.png);'
            "
          >
          </b-col>
        </b-row>
      </div> </b-tab
  ></b-tabs>
</template>

<script>
export default {
  name: "login",
  props: ["config"],
  data() {
    return {
      tab: "login",
      form: {
        userame: "",
        password: "",
      },
    };
  },
};
</script>

<style scoped>
::v-deep .nav-tabs {
  background-color: #8ac2e6;
}

::v-deep .nav-tabs > .nav-item > .nav-link {
  color: #0071b9;
  padding: 0.5rem 1.2rem 0.5rem 0rem;
}
::v-deep .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
  color: #fff;
}
::v-deep .nav-tabs > .nav-item > .nav-link.active {
  background-color: transparent;
  border: 0;
  color: white;
}
::v-deep .nav-tabs > .nav-item:first-child {
  color: white;
  padding: .5rem 3rem .5rem 1rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: #0071b9;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .nav-tabs > .nav-item:first-child > .nav-link {
  color: #fff;
}
.miniMenu {
  height: 18px;
  width: auto;
}
.miniMenuTitle {
  margin-left: 1rem;
}
.topRow {
  padding: 0;
  margin: 0;
}
.content {
  padding: 1rem 5em;
}
.login {
  padding: 1.5rem 0 2rem;
}
::v-deep .smallNav > div > .nav-tabs > .nav-item {
  padding: 0;
}
::v-deep .smallNav > div > .nav-tabs {
  background-color: #fff;
  border-bottom: 0;
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: #0071b9;
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  background-color: #8ac2e6;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  background-color: #8ac2e6;
  color: #fff;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item:first-child {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bottomTabs > .tab-content {
  margin-top: 25px;
  border-top: 1px solid #e5e5e5;
}
.title {
  color: #0071b9;
}
.loginBtn{
    float: right;
    background-color: #0071b9;
    border-color: #0071b9;
}
.forPass{
    color:#0071b9;
}
@media (min-width: 992px) {
  .my-login .my-brand {
    display: flex;
    align-self: stretch;
    background-color: #0071b9;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-clip-path: polygon(0 100%, 20% 0, 100% 0, 100% 100%);
    clip-path: polygon(0 100%, 20% 0, 100% 0, 100% 100%);
    flex-basis: 50%;
  }
  .my-login {
    display: flex;
    align-items: center;
    min-height: calc(100vh - calc(88px + 128px + 50px));
  }
}
</style>
