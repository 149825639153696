<template>
  <div>
    <b-tabs v-model="tabIndex" class="bigNav">
      <b-tab active>
        <template #title>
          <b-img
            class="miniMenu"
          :src="config.VUE_APP_WP_ASSETS+'assets/grid-white.png'"
          ></b-img>
          <span class="miniMenuTitle"> News </span>
        </template>
      
      <div class="content grey">
        <b-row class="topRow">
          <b-col lg="10" >
            <div class="choose">
              <div class="title">
                <h2 class="text">
                  Scottish Golf News
                </h2>
              </div>
              <div class="select">
                <b-row>
                  <b-col lg='4' md='12'>
                    <label class="mr-sm-2 cat" for="cat-sel"
                      >News Category</label
                    >
                    <b-form-select
                      id="cat-sel"
                      class="cat"
                      v-model="selected"
                      :options="cat"
                      text-field="name"
                      value-field="id"
                    ></b-form-select>
                  </b-col>
                  <b-col lg='4' md='12' >
                    <b-form-group
                      id="input-serach"
                      label="Search"
                      label-for="search"
                      class='ser'
                    >
                      <b-input-group size="md" >
                        <b-form-input
                          id="search"
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg='4' md='12'
                    ><div class="go">
                      <b-button @click="changeUrl">Go</b-button>
                    </div></b-col
                  >
                </b-row>
              </div>
            </div>
            <div>
              <h2 class="text marg">Latest News</h2>
            </div>
            <div>
              <NewsPage :posts="posts" :filter="filter" />
            </div>
          </b-col>
          <b-col lg="2" >
            Advert
          </b-col>
        </b-row>
      </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import NewsPage from "@/components/newsPage.vue";
export default {
  name: "news",
  components: { NewsPage },
  props:['config'],
  data() {
    return {
      cat: [],
      selected: "",
      filter: "",
      posts: [],
      tabIndex: 0,
    };
  },
  computed:{
    selectedC:function(){
      return this.selected
    }
  },
  methods: {
    changeUrl() {
      var json = "wp/v2/posts?categories=" + this.selected + "&per_page=100"
      return apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.posts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
  },
       watch: {
    selectedC: function() {
      this.changeUrl();
      this.loading = true;
    }
  },
    async created() {
    var one = "wp/v2/categories?per_page=100";
    var two = "wp/v2/posts?per_page=100";
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(one);
      this.cat = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(two);
      this.posts = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
::v-deep label#input-serach__BV_label_ {
  color: #0071b9;
}
::v-deep .nav-tabs {
  background-color: #8ac2e6;
  border-bottom: 0;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:first-child > .nav-link {
  color: #fff;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item {
  background-color: #8ac2e6;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item > .nav-link {
  color: #0071b9;
}
::v-deep .bigNav > div > .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
  color: #fff;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: transparent;
  border: 0;
  color: white;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:first-child {
  color: white;
  padding: .5rem 3rem .5rem 1rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: #0071b9;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:not(:first-child) {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
.miniMenu {
  height: 18px;
  width: auto;
}
.miniMenuTitle {
  margin-left: 1rem;
}
.topRow {
  padding: 0;
  margin: 0 0 2rem 0;
}
.content {
  padding: 1rem 1rem;
}
.grey {
  background-color: #f2f2f2;
}
.choose {
  background-color: white;
  margin-bottom: 2rem;
  display: block;
  border: solid 1px #e5e5e5;
  padding: 1rem;
}
.title {
  margin-bottom: 1rem;
}
.text {
  margin-bottom: 0;
  white-space: nowrap;
  font-size: 20px;
  color: #0071b9;
  line-height: 1.2;
}
.select {
  display: block;
}
.cat {
  width: 95%;
  margin-left: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
label {
  color: #0071b9;
  margin-bottom: 0.5rem;
}
.go {
  flex-grow: 0;
  flex-basis: auto;
  padding: 2rem 2rem 0 2rem;
}
.go > .btn {
  width: auto;
  padding: 0.375rem 3rem;
  background-color: #0071b9;
  border-color: #0071b9;
}
.marg{
    margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .choose {
    display: flex;
    padding: 1.5rem;
  }
  .title {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  .select {
    align-items: center;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .cat{
    width: 100%;
   margin-left: 0rem;
  }
}
</style>
