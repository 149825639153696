<template>
  <div>
    <template v-if="type === 'customList'">
      <div
        class="accordion"
        role="tablist"
        v-for="(items, index) in customList"
        :key="index"
      >
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="listBtn" block v-b-toggle="'accordion-' + index"
              >{{ items.title
              }}<span class="plus">
                <font-awesome-icon
                  id="fb"
                  class="icon"
                  :icon="['fa', 'plus']"
                />
              </span>
              <span class="minus">
                <font-awesome-icon
                  id="fb"
                  class="icon"
                  :icon="['fa', 'minus']"
                />
              </span>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + index"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col lg="2">
                    <Logo :logo="items.image" />
                    <b-img
                      class="faqLogo"
                      :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/01/Scottish_Golf_AlignedLeft_PANTONE300u-300x109.png'"
                    ></b-img>
                  </b-col>
                  <b-col>
                    <span style="float:left;" v-html="items.description"></span>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-collapse> 
        </b-card>
      </div>
    </template>
    <template v-if="type === 'test'">
      <div
        class="accordion"
        role="tablist"
        v-for="(items, index) in customList"
        :key="index"
      >
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button class="listBtn" block v-b-toggle="'accordion-' + index"
              >{{ items.title
              }}<span class="plus">
                <font-awesome-icon
                  id="fb"
                  class="icon"
                  :icon="['fa', 'plus']"
                />
              </span>
              <span class="minus">
                <font-awesome-icon
                  id="fb"
                  class="icon"
                  :icon="['fa', 'minus']"
                />
              </span>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + index"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col lg="2">
                    <Logo :logo="items.image" />
                  </b-col>
                  <b-col>
                    <span style="float:left;" v-html="items.description"></span>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
    <template v-if="type === 'partners'">
      <b-row>
        <b-col lg="4" v-for="(items, index) in customList" :key="index">
          <div class="item">
            <b-button class="tab" @click="changeTab(index)">
            <Logo :logo="items.logo" :title="items.title"/>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import Logo from '@/components/partLogo.vue'

export default {
  name: "customList",
  props: ["customList", "type", 'config'],
  components:{Logo},

  methods:{
    changeTab(index){
      this.$emit("tab", { index: index +1 });
    }
  },
};
</script>

<style scoped>
.listBtn {
  font-size: 20px;
  text-align: left;
  padding: 1.5rem;
  background-color: transparent;
  color: #0071b9;
  border: 0;
}
::v-deep .card-header {
  background-color: transparent;
}
.collapsed > .plus {
  display: block;
}
.not-collapsed > .plus {
  display: none;
}
.collapsed > .minus {
  display: none;
}
.not-collapsed > .minus {
  display: block;
}
.plus {
  float: right;
}
.minus {
  float: right;
}
.faqLogo {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
  width: 300px;
}
.item {
  background-color: white;
  border: solid 1px #e5e5e5;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin-bottom: 1rem;
  height: 350px;
}
.tab{
  width: 100%;
  background-color: transparent;
  height: 100%;
  border: 0;
}
@media (min-width: 1600px) {
  .item {
    padding: 5rem 10rem;
  }
}
@media (min-width: 1440px) {
  .item {
    padding: 5rem 7rem;
  }
}
@media (min-width: 992px) {
  .item {
    padding: 5rem;
  }
}
</style>
