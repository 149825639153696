<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab>
        <template #title>
          <b-img
            class="miniMenu"
            :src="config.VUE_APP_WP_ASSETS+'assets/grid-white.png'"
          ></b-img>
          <span class="miniMenuTitle" v-html="wpPage[0].title.rendered"></span>
        </template>
        <div class="content grey">
          <b-row class="topRow">
            <b-col :class="{ 'col-lg-10': wpPage[0].acf.enable_sidebar === true }">
              <Background :img="false" :title="wpPage[0].title.rendered" />
              <div :class="{ container: page === 'OpenPlay' }">
                <span
                  class="my-content"
                  v-html="wpPage[0].content.rendered"
                ></span>
              </div>
            </b-col>
          </b-row>
        </div> 
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Background from "@/components/backgroundSplash.vue";
import apiCall from "@/services/api.js";
export default {
  name: "single",
  components: { Background },
  props:['config'],
  data() {
    return {
      wpPage: [],
    };
  },
  created(){
   var json = "wp/v2/pages?search=" + this.$route.query.id
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.wpPage = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
::v-deep .nav-tabs {
  background-color: #8ac2e6;
}
::v-deep .nav-tabs > .nav-item {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .nav-tabs > .nav-item > .nav-link {
 color: #0071b9;
 padding: 0.5rem 1.2rem 0.5rem 0rem;
}
::v-deep .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
  color: #fff;
}
::v-deep .nav-tabs > .nav-item > .nav-link.active {
  background-color: transparent;
  border: 0;
  color: white;
}
::v-deep .nav-tabs > .nav-item:first-child {
  color: white;
  padding: .5rem 3rem .5rem 1rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: #0071b9;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  
}
::v-deep .nav-tabs > .nav-item:first-child>.nav-link{
color: #fff;
}
.miniMenu {
  height: 18px;
  width: auto;
}
.miniMenuTitle {
  margin-left: 1rem;
  
}
.topRow {
  padding: 0;
  margin: 0;
}
.content {
  padding: 1rem 0;
}
.grey {
  background-color: #f2f2f2;
}
::v-deep .my-template .my-page .my-content img {
    width: 100%;
    height: auto;
}
::v-deep .wp-block-image img {
    max-width: 100%;
}
::v-deep .wp-block-image .aligncenter {
    margin-left: auto!important;
    margin-right: auto!important;
}
::v-deep .wp-block-image .aligncenter, .wp-block-image .alignleft, .wp-block-image .alignright, .wp-block-image.is-resized {
    display: table;
    margin-left: 0;
    margin-right: 0;
}
::v-deep .wp-block-image {
    max-width: 100%;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
}
::v-deep .my-template .my-page .my-content h1, .my-template .my-page .my-content h2, .my-template .my-page .my-content h3 {
    margin-bottom: 1.5rem !important;
}
::v-deep h2 {
    font-size: 20px;
    color: #0071b9!important;
}

</style>
