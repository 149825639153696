<template>
  <div>
    <b-tabs v-model="tabIndex" class="bigNav">
      <b-tab active>
        <template #title>
        <b-nav-item to="/news">
          <b-img
            class="miniMenu"
          :src="config.VUE_APP_WP_ASSETS+'assets/grid-white.png'"
          ></b-img>
          <span class="miniMenuTitle"> News </span>
        </b-nav-item>
        </template>
      </b-tab>
      <div class="content grey">
        <b-container>
        <b-row class="topRow">
          <b-col lg="10">
              <div class='story'>
                  <h1 class='title' v-html="story.title.rendered">
                  </h1>
                  <h4 class='date'>
                    Posted on {{dateTime}}
                  </h4>
                  <b-img class='storyImg' :src="story.better_featured_image.source_url"></b-img>
                  <span v-html="story.content.rendered"></span>
              </div>
          </b-col>
        </b-row>
        </b-container>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
    name:'newsStory',
    props:['config'],
    data(){
        return{
            story:[]
        }
    },
        computed: {
    dateTime:function(){
      var dateOG = this.story.date.split('T')
      var splitDate = dateOG[0].split('-')
      var newDate = splitDate[2]+ '/' + splitDate[1] + '/' + splitDate[0]
      var time = dateOG[1]
      return newDate + ' - ' + time

    }
  },
      created(){
   var json = "wp/v2/posts/" + this.$route.query.id
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.story = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

}
</script>

<style scoped>
::v-deep .nav-tabs {
  background-color: #8ac2e6;
  border-bottom: 0;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:first-child > .nav-link {
  color: #fff;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item {
  background-color: #8ac2e6;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item > .nav-link {
  color: #0071b9;
}
::v-deep .bigNav > div > .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
  color: #fff;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: transparent;
  border: 0;
  color: white;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:first-child {
  color: white;
  padding: .5rem 3rem .5rem 1rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: #0071b9;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:not(:first-child) {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
.miniMenu {
  height: 18px;
  width: auto;
}
.miniMenuTitle {
  margin-left: 1rem;
  color:white;
}
.topRow {
  padding: 0;
  margin: 0 0 2rem 0;
}
.content {
  padding: 2rem 1rem;
}
.grey {
  background-color: #f2f2f2;
}
.title{
    margin-bottom: 25px!important;
    font-size: 35px;
    color: #0071b9;
}
.date{
  color: #0071b9;
  font-size: 16px;
}
.storyImg{
    width: 75%;
    padding-bottom: 25px;
}
::v-deep a{
    color: #0071b9;
}
</style>