<template>
  <div>
    <b-row>
      <template v-for="(feat, index) in indexData.tournaments">
        <b-col
          lg="6"
          md="12"
          cols="12"
          class="mobCol"
          :key="index"
          v-if="feat.data.featured === 'Y'"
        >
          <div class="content" :style="'background-image: url('+config.VUE_APP_WP_DIGITAL_OCEAN+'2020/01/DEPARMENT-HEADER-IMAGE-V1-300x111.png);'">
            <div class="overLay">
              <div class="mainDetails">
                <div class="dates">
                  {{ feat.data.dates }}
                </div>
                <h3 class="closeDate">
                  Entries close: {{ feat.data.closing_date }} at
                  {{ feat.data.closing_time }}
                </h3>
                <h1 class="tourName">{{ feat.data.short_name }}</h1>
                <h2 class="ven">{{ feat.data.course }}</h2>
              </div>
              <div class="links">
                <b-button class="moreBtn"
                  >More Info
                  <span v-if="isClosed(feat) === false"
                    >/ Enter
                  </span></b-button
                >
              </div>
              <div class="details">
                <div class="item">
                  <small>
                    Rounds

                    <div>
                      {{ feat.data.rounds }}
                    </div>
                  </small>
                </div>
                <div class="item">
                  <small>
                    Tee

                    <div>
                      <span class="tee" :class="feat.data.tee_colour"></span>
                    </div>
                  </small>
                </div>
                <div class="item">
                  <small>
                    Fee

                    <div>£{{ feat.data.basic_fee }}</div>
                  </small>
                </div>
                <div class="item">
                  <small>
                    Age

                    <div>
                      {{ feat.data.check_age_desc }}
                    </div>
                  </small>
                </div>
                <div class="item">
                  <small>
                    Gender

                    <div>
                      <span v-if="feat.data.t_image_1 === ''"></span>
                      <span v-else>
                        <font-awesome-icon
                          v-if="feat.data.t_image_1 === 'ion-man'"
                          id="fb"
                          class="icon male"
                          :icon="['fa', 'male']"
                        /><font-awesome-icon
                          v-if="feat.data.t_image_1 === 'ion-woman'"
                          id="fb"
                          class="icon female"
                          :icon="['fa', 'female']"
                        />
                      </span>
                    </div>
                  </small>
                </div>
                <div class="item">
                  <small>
                    H/Cap

                    <div>
                      {{ feat.data.check_hcap_desc }}
                    </div>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "enventContent",
  props:['config'],
  data() {
    return {
      indexData: [],
    };
  },
  methods: {
    isClosed(feat) {
      var cd = feat.data.closing_date;
      var ct = feat.data.closing_time;
      var closeDate = new Date(cd + "T" + ct).getTime();
      var todayDate = new Date().getTime();

      return todayDate >= closeDate;
    },
  },
  mounted() {
    axios //add API Call
      .get(process.env.VUE_APP_TIC_BASE+process.env.VUE_APP_CLUB_ID+'/cache/TOUR/index') // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.indexData = response.data)); //API data wanted pulled out
  },
};
</script>

<style scoped>
.content {
  
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  background-size: cover;
  background-position: center;
  background-color: white;
}
.overLay {
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  padding: 2rem;
  color: white;
}
.mainDetails {
  margin-bottom: 1rem;
}
.links {
  margin-bottom: 1.5rem;
}
.details {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  flex-flow: row wrap;
}
.dates {
  font-size: 1.6rem;
  font-weight: bold;
}
.closeDate {
  color: white !important;
  font-size: 16px;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  margin-top: 0;
}
.tourName {
  font-size: 32px;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  margin-top: 0;
}
.ven {
  margin-bottom: 0.5rem;
  line-height: 1.2;
  margin-top: 0;
  font-size: 20px;
}
.moreBtn {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  padding: 0.375rem 3rem;
}
.item {
  padding-right: 1.25rem;
  border-right: solid 1px rgba(255, 255, 255, 0.1);
  margin-right: 1.25rem;
}
.tee {
  height: 0.75rem;
  width: 0.75rem;
  display: inline-block;
  border: solid 1px #e5e5e5;
  border-radius: 50px;
}
.W {
  background-color: #fff;
}
.R {
  background-color: #f00;
}
.B {
  background-color: #000;
}
.male {
  color: dodgerblue;
}
.female {
  color: deeppink;
}
@media (max-width: 768px) {
  .mobCol {
    padding-bottom: 20px;
  }
}
</style>
