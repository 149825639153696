<template>
  <div>
    <b-tabs v-model="tabIndex" class="bigNav">
      <b-tab active>
        <template #title>
          <b-img
            class="miniMenu"
            :src="config.VUE_APP_WP_ASSETS + 'assets/grid-white.png'"
          ></b-img>
          <span class="miniMenuTitle"> Competition </span>
        </template>
        <div
          class="compBanner"
          :style="'background-image: url(' + banner + ');'"
        >
          <div class="overLay">
            <div class="container-fluid overText">
              <h3>{{ comp.dates }}</h3>
              <h1>{{ comp.short_name }}</h1>
              <h2>{{ comp.course_name }}</h2>
              <div class="flexDetails">
                <div class="flexItem">
                  <small>
                    Rounds
                  </small>
                  <div>
                    {{ comp.rounds }}
                  </div>
                </div>
                <div class="flexItem">
                  <small>
                    Tee
                  </small>
                  <div>
                    <span class="tee" :class="comp.tee_colour"></span>
                  </div>
                </div>
                <div class="flexItem">
                  <small>
                    Fee
                  </small>
                  <div>£{{ comp.basic_fee }}</div>
                </div>
                <span v-if="comp.check_age === 'N'"></span>
                <div v-else class="flexItem">
                  <small>
                    Age
                  </small>
                  <div>
                    {{ comp.check_age_desc }}
                  </div>
                </div>
                <div class="flexItem">
                  <small>
                    Gender
                  </small>
                  <div>
                    <span v-if="comp.t_image_1 === ''"></span>
                    <span v-else>
                      <font-awesome-icon
                        v-if="comp.t_image_1 === 'ion-man'"
                        id="fb"
                        class="icon male"
                        :icon="['fa', 'male']"
                      /><font-awesome-icon
                        v-if="comp.t_image_1 === 'ion-woman'"
                        id="fb"
                        class="icon female"
                        :icon="['fa', 'female']"
                      />
                    </span>
                    <span v-if="comp.t_image_2 === ''"></span>
                    <span v-else>
                      <font-awesome-icon
                        v-if="comp.t_image_2 === 'ion-man'"
                        id="fb"
                        class="icon male"
                        :icon="['fa', 'male']"
                      /><font-awesome-icon
                        v-if="comp.t_image_2 === 'ion-woman'"
                        id="fb"
                        class="icon female"
                        :icon="['fa', 'female']"
                      />
                    </span>
                  </div>
                </div>
                <span v-if="comp.check_hcap === 'N'"></span>
                <div v-else class="flexItem">
                  <small>
                    H/Cap
                  </small>
                  <div>
                    {{ comp.check_hcap_desc }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content grey">
          <b-row class="topRow ">
            <b-col lg="10">
              <b-nav-item to="/events"
                ><font-awesome-icon id="fb" :icon="['fa', 'arrow-left']" />
                Back</b-nav-item
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="10">
              <b-card-group deck>
                <b-card
                  text-variant="#737373"
                  header="Competition"
                  class="text-center"
                >
                  <b-card-text style="text-align:left;">
                    <div>{{ comp.short_name }}</div>
                    <div>{{ comp.course_name }}</div>
                    <div>{{ comp.dates }}</div>
                    <div>
                      {{ comp.rounds }} Round<span v-if="comp.rounds > 1"
                        >s</span
                      >
                    </div>
                    <div>
                      Tee <span class="tee" :class="comp.tee_colour"></span>
                    </div>
                    <div>Entry Fee: £{{ comp.basic_fee }}</div>
                    <div>
                      Gender:
                      <span v-if="comp.t_image_1 === ''"></span>
                      <span v-else>
                        <font-awesome-icon
                          v-if="comp.t_image_1 === 'ion-man'"
                          id="fb"
                          class="icon male"
                          :icon="['fa', 'male']"
                        /><font-awesome-icon
                          v-if="comp.t_image_1 === 'ion-woman'"
                          id="fb"
                          class="icon female"
                          :icon="['fa', 'female']"
                        />
                      </span>
                      <span v-if="comp.t_image_2 === ''"></span>
                      <span v-else>
                        <font-awesome-icon
                          v-if="comp.t_image_2 === 'ion-man'"
                          id="fb"
                          class="icon male"
                          :icon="['fa', 'male']"
                        /><font-awesome-icon
                          v-if="comp.t_image_2 === 'ion-woman'"
                          id="fb"
                          class="icon female"
                          :icon="['fa', 'female']"
                        />
                      </span>
                    </div>
                    <span v-if="comp.check_age === 'N'"></span>
                    <div v-else>
                      <small>
                        Age:
                      </small>
                      <span>
                        {{ comp.check_age_desc }}
                      </span>
                    </div>
                    <span v-if="comp.check_hcap === 'N'"></span>
                    <div v-else>
                      <small>
                        H/Cap:
                      </small>
                      <span>
                        {{ comp.check_hcap_desc }}
                      </span>
                    </div>
                    <b-button class="login green"
                      >Login / Register to Enter</b-button
                    >
                  </b-card-text>
                </b-card>

                <b-card
                  v-if="comp.error_reason !== 'Tournament record missing'"
                  text-variant="#737373"
                  header="Venue Details"
                  class="text-center"
                >
                  <b-card-text style="text-align:left;">
                    <div v-if="comp.clubs.clubs_entry.cname.length">
                      {{ comp.clubs.clubs_entry.cname }}
                    </div>
                    <div v-if="comp.clubs.clubs_entry.addr1.length">
                      {{ comp.clubs.clubs_entry.addr1 }}
                    </div>
                    <div v-if="comp.clubs.clubs_entry.addr2.length">
                      {{ comp.clubs.clubs_entry.addr2 }}
                    </div>
                    <div v-if="comp.clubs.clubs_entry.addr3.length">
                      {{ comp.clubs.clubs_entry.addr3 }}
                    </div>
                    <div v-if="comp.clubs.clubs_entry.addr4.length">
                      {{ comp.clubs.clubs_entry.addr4 }}
                    </div>
                    <div v-if="comp.clubs.clubs_entry.addr5.length">
                      {{ comp.clubs.clubs_entry.addr5 }}
                    </div>
                    <div>
                      <b-button
                        :href="'tel:' + comp.clubs.clubs_entry.contact2_tele"
                        class="login blue"
                        ><font-awesome-icon
                          id="fb"
                          class="icon"
                          :icon="['fa', 'phone']"
                        />
                        Call</b-button
                      ><b-button
                        :href="
                          'mailto:' + comp.clubs.clubs_entry.contact2_email
                        "
                        class="login blue"
                        ><font-awesome-icon
                          id="fb"
                          class="icon"
                          :icon="['fa', 'envelope']"
                        />
                        Email</b-button
                      >
                    </div>
                  </b-card-text>
                </b-card>

                <b-card
                  v-if="comp.error_reason !== 'Tournament record missing'"
                  text-variant="#737373"
                  header="Event Administrator(s)"
                  class="text-center"
                >
                  <b-card-text style="text-align:left;">
                    <div v-if="comp.clubs.clubs_entry.cname.length">
                      {{ comp.administrators.administrators_entry.description }}
                    </div>
                    <div v-if="comp.clubs.clubs_entry.addr1.length">
                      Position:
                      {{ comp.administrators.administrators_entry.position }}
                    </div>
                    <div v-if="comp.clubs.clubs_entry.addr2.length">
                      Role: {{ comp.administrators.administrators_entry.role }}
                    </div>
                    <div>
                      <b-button
                        :href="
                          'mailto:' +
                            comp.administrators.administrators_entry.email
                        "
                        class="login blue"
                        ><font-awesome-icon
                          id="fb"
                          class="icon"
                          :icon="['fa', 'envelope']"
                        />
                        Email</b-button
                      >
                    </div>
                  </b-card-text>
                </b-card>
              </b-card-group>
            </b-col>
          </b-row>
          <template v-if="title !== ''">
            <div class="secContent">
              <b-button
                class="fpBtn"
                v-if="filterEntry.length"
                :class="{ active: title === 'Tournament Entries' }"
                title="Tournament Entries"
                :value="filterEntry[0].report_url"
                @click="changeReport"
                >Entries</b-button
              >
              <b-button
                class="fpBtn"
                v-if="filterDraw.length"
                :class="{ active: title === 'Draw' }"
                title="Draw"
                :value="filterDraw[0].report_url"
                @click="changeReport"
                >Draw</b-button
              >
              <b-button
                class="fpBtn"
                v-if="filterScore.length"
                :class="{ active: title.includes('Score') }"
                title="Scores"
                :value="filterScore[0].report_url"
                @click="changeReport"
                >Score</b-button
              >
              <template v-if="title.includes('Draw')">
                <div class="secContent">
                  <b-button
                    v-for="(button, index) in filterDraw"
                    :class="{ active: title === button.report_title }"
                    :key="index"
                    @click="changeReport"
                    :title="button.report_title"
                    :value="button.report_url"
                    class="fpBtn"
                    >{{ button.report_title }}</b-button
                  >
                </div>
              </template>
              <template v-if="title.includes('Score')">
                <div class="secContent">
                  <b-button
                    v-for="(button, index) in filterScore"
                    :class="{ active: title === button.report_title }"
                    :key="index"
                    @click="changeReport"
                    :title="button.report_title"
                    :value="button.report_url"
                    class="fpBtn"
                    >{{ button.report_title }}</b-button
                  >
                </div>
              </template>
              <ReportTable
                :data="data"
                :Eds="Eds"
                :title="title"
                :ud="ud"
                :id="this.$route.query.id"
              />
              <div>
                <b-button class="login blue" @click="ud = ud + 15">
                  More</b-button
                >
                <b-button class="login blue" @click="ud = ud - 15">
                  Less</b-button
                >
                <b-button class="login blue" @click="ud = arrayLength">
                  All</b-button
                >
              </div>
            </div>
          </template>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
//import ReportTable from "@/components/reportTable.vue";
import axios from "axios";
export default {
  name: "competition",
  //   components: { ReportTable },
  props: ["config"],
  data() {
    return {
      comp: [],
      reports: [],
      data: [],
      title: "",
      currentReport: "",
      ud: 15,
      active: 0,
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("value");
      var title = event.target.getAttribute("title");
      return (
        ((this.currentReport = report), (this.title = title)),
        axios
          .get(
            process.env.VUE_APP_TIC_XML +
              this.currentReport +
              "enhanced=y~jsout=x~xsl=~randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.data = response.data))
      );
    },
  },
  computed: {
    banner(banner) {
      if (this.comp.course_images === "N")
        banner =
          process.env.VUE_APP_WP_DIGITAL_OCEAN +
          "2020/01/DEPARMENT-HEADER-IMAGE-V1-1024x380.png";
      else
        banner =
          process.env.VUE_APP_VMS_DIGITAL_OCEAN +
          this.comp.course_image_file_10;
      return banner;
    },
    arrayLength(reportData) {
      if (this.title === "Tournament Entries")
        reportData = this.data.entries.entries_entry.length;
      else if (this.title.includes("Draw"))
        reportData = this.data.draw.draw_entry.length;
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard") ||
        this.title.includes("Live")
      )
        reportData = this.data.scores.scores_entry.length;
      else if (this.title.includes("Final"))
        reportData = this.data.result.result_entry.length;
      return reportData;
    },
    Eds(reportData) {
      var ud = this.ud;
      if (this.title === "Tournament Entries")
        reportData = this.data.entries.entries_entry.slice(0, ud);
      else if (this.title.includes("Draw"))
        reportData = this.data.draw.draw_entry.slice(0, ud);
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard") ||
        this.title.includes("Live")
      )
        reportData = this.data.scores.scores_entry.slice(0, ud);
      else if (this.title.includes("Final"))
        reportData = this.data.result.result_entry.slice(0, ud);
      return reportData;
    },
    filterEntry: function() {
      return this.reports.filter((report) =>
        report.report_title.includes("Tour")
      );
    },
    filterDraw: function() {
      return this.reports.filter((report) =>
        report.report_title.includes("Draw")
      );
    },
    filterScore: function() {
      return this.reports.filter((report) =>
        report.report_title.includes("Score")
      );
    },
    filterFinal: function() {
      return this.reports.filter((report) =>
        report.report_title.includes("Final")
      );
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_WP_ASSETS +
          "inc/xml2json.php?url=" +
          process.env.VUE_APP_TICX +
          "tmticx.cgi?season=TOUR~tourn=" +
          this.$route.query.id +
          "~freetext=Y~club~admin~enhanced=Y~jsout=x~xsl=~randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        (this.comp = response.data),
          (this.reports = response.data.reports.reports_entry),
          (this.currentReport =
            response.data.reports.reports_entry[0].report_url),
          (this.title = response.data.reports.reports_entry[0].report_title);
        return axios.get(
          process.env.VUE_APP_TIC_XML +
            this.currentReport +
            "enhanced=y~jsout=x~xsl=~randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.data = response.data;
      });
  },
};
</script>

<style scoped>
li {
  list-style: none;
}
a {
  color: #0071b9;
}
.secContent {
  padding: 2rem 0;
}
::v-deep .nav-tabs {
  background-color: #8ac2e6;
  border-bottom: 0;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:first-child > .nav-link {
  color: #fff;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item {
  background-color: #8ac2e6;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item > .nav-link {
  color: #0071b9;
}
::v-deep .bigNav > div > .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
  color: #fff;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: transparent;
  border: 0;
  color: white;
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:first-child {
  color: white;
  padding: 0.5rem 3rem 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  background-color: #0071b9;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .bigNav > div > .nav-tabs > .nav-item:not(:first-child) {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
.miniMenu {
  height: 18px;
  width: auto;
}
.miniMenuTitle {
  margin-left: 1rem;
}
.topRow {
  padding: 0;
  margin: 0 0 2rem 0;
}
.content {
  padding: 1rem 1rem;
}
.grey {
  background-color: #f2f2f2;
}
.compBanner {
  background-size: cover;
  background-position: center;
  position: relative;
}
.overLay {
  padding: 1rem 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  min-height: 200px;
}
.overText > h1 {
  color: white !important;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 32px;
}
.overText > h2 {
  color: white !important;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 20px;
}
.overText > h3 {
  color: white !important;
  font-size: 16px;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.flexDetails {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  text-align: center;
  color: white;
  margin-top: 1rem;
}
.flexDetails .flexItem {
  padding-right: 1rem;
}
.tee {
  height: 0.75rem;
  width: 0.75rem;
  display: inline-block;
  border: solid 1px #e5e5e5;
  border-radius: 50px;
}
.W {
  background-color: #fff;
}
.R {
  background-color: #f00;
}
.B {
  background-color: #000;
}
.male {
  color: dodgerblue;
}
.female {
  color: deeppink;
}
::v-deep .card-header {
  background-color: #0071b9;
  padding: 1rem;
  color: #fff;
  border-radius: 0;
}
.login {
  padding: 0.375rem 3rem;
  color: #fff;
  margin-right: 5px;
  margin-top: 1rem !important;
}
.green {
  background-color: #28a745;
  border-color: #28a745;
}
.blue {
  background-color: #0071b9;
  border-color: #0071b9;
}

::v-deep .smallNav > div > .nav-tabs {
  background-color: #f2f2f2;
  border-bottom: 0;
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: #0071b9;
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  background-color: #8ac2e6;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  background-color: #8ac2e6;
  color: #fff;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item > .nav-link {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
::v-deep .smallNav > div > .nav-tabs > .nav-item:first-child {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
}
.fpBtn {
  color: #fff;
  background-color: #9bcbea;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 2rem 100%);
  padding: 1rem 3rem 1rem 3rem;
  margin-left: calc(-2rem - 1px);
}
.fpBtn:first-child {
  clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, calc(100% - 2rem) 0, 100% 100%, 0 100%);
  padding: 1rem 3rem 1rem 1.5rem;
  margin-left: 0;
}

.fpBtn.active {
  background-color: #0071b9 !important;
}
@media (min-width: 992px) {
  .overLay {
    min-height: 300px;
    padding: 1rem 2rem;
  }
  .flexDetails .flexItem {
    border-right: solid 1px #e5e5e5;
    margin-right: 1rem;
  }
}
</style>
