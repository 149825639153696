var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tabs',{scopedSlots:_vm._u([{key:"tabs-end",fn:function(){return [_c('b-nav-item',{attrs:{"to":{
              name: 'Official Partners',
              query: {
                id: 451,
              },
            },"role":"presentation"}},[_vm._v("Offical Partners")])]},proxy:true}]),model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('b-img',{staticClass:"miniMenu",attrs:{"src":_vm.config.VUE_APP_WP_ASSETS+'assets/grid-white.png'}}),_c('span',{staticClass:"miniMenuTitle"},[_vm._v(" "+_vm._s(_vm.wpPage.title.rendered)+" ")])]},proxy:true}])},[_c('div',{staticClass:"content grey"},[_c('b-row',{staticClass:"topRow"},[_c('b-col',{class:{ 'col-lg-10': _vm.wpPage.acf.enable_sidebar === true }},[_c('Background',{attrs:{"img":_vm.banner(),"title":_vm.wpPage.title.rendered}}),_c('div',{class:{ container: _vm.page === 'OpenPlay' }},[_c('span',{staticClass:"my-content",domProps:{"innerHTML":_vm._s(_vm.wpPage.content.rendered)}})]),(_vm.page === 'Handicapping' || _vm.page === 'Club Support')?_c('div',_vm._l((_vm.wpPageChild.slice().reverse()),function(topList,index){return _c('b-row',{key:index},[_c('b-col',{staticClass:"myPost"},[_c('div',{staticClass:"myBox"},[_c('div',{staticClass:"myFlex myImg",style:('background-image:url(' +
                          topList.better_featured_image.source_url +
                          ');')}),_c('div',{staticClass:"myFlex myTitle"},[_c('div',{staticClass:"myText"},[_c('h3',[_vm._v(" "+_vm._s(topList.title.rendered)+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(topList.excerpt.rendered)}})])]),_c('div',{staticClass:"myFlex myLinks"},[_c('b-button',{staticClass:"linkGrey",on:{"click":function($event){_vm.tabIndex = index + 1}}},[_vm._v("More Info")])],1)])])],1)}),1):_vm._e()],1)],1)],1)]),(_vm.wpPageChild.length !== 0)?_vm._l((_vm.wpPageChild.slice(3, 12).reverse()),function(tab,index){return _c('b-tab',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"miniMenuTitle",domProps:{"innerHTML":_vm._s(tab.title.rendered)}})]},proxy:true}],null,true)},[_c('div',{staticClass:"content grey"},[_c('b-row',{staticClass:"topRow"},[_c('b-col',{class:{ 'col-lg-10': tab.acf.enable_sidebar === true }},[_c('Background',{attrs:{"img":tab.acf.banner_image,"title":tab.title.rendered}}),_c('div',{class:{ container: _vm.page === 'OpenPlay' }},[_c('span',{staticClass:"my-content",domProps:{"innerHTML":_vm._s(tab.content.rendered)}}),_c('CustomList',{attrs:{"customList":tab.acf.custom_list,"type":"customList","config":_vm.config}}),_c('CustomList',{attrs:{"customList":tab.acf.partner,"type":"partners","config":_vm.config}})],1)],1)],1)],1)])}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }