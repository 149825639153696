<template>
  <div>
      <div v-if="this.title === 'Terms &#038; Conditions'"><b-container><h1 class="noSplashTitle" v-html="title"></h1></b-container></div>
      <div v-else-if="this.img === false || this.img === undefined" class="background" :style="'background-image: url(' + backgroundImg + ');'">
        <h1 class="splashTitle" v-html="title"></h1>
      </div>
    <div v-else
      class="background"
      :style="'background-image: url(' + backgroundImg.source_url + ');'"
    >
      <h1 class="splashTitle" v-html="title"></h1>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "background",
  props: ["img", "title"],
  data() {
    return {
      backgroundImg: [],
    };
  },
  mounted() {
      if (this.img === false || this.img === undefined){
          this.backgroundImg = process.env.VUE_APP_WP_DIGITAL_OCEAN+'2020/01/DEPARMENT-HEADER-IMAGE-V1-1024x380.png'
      }
      else
    axios //add API Call
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/media/" + this.img) // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.backgroundImg = response.data)); //API data wanted pulled out
  },
};
</script>

<style scoped>
.background {
  padding: 8rem 4rem;
  margin-bottom: 4rem;
  background-position: center;
  background-size: cover;
  background-color: white;
  color: white;
}
.splashTitle {
  font-size: 32px;
}
.noSplashTitle{
font-size: 35px;
    color: #0071b9;
    margin-bottom: 18px!important;
    padding-top: 2rem;
}
</style>
