<template>
  <b-row id="itemList">
    <b-col
      lg="3"
      v-for="(post, index) in filteredList"
      :key="index"
      class="postCol"
    >
      <b-card
        :img-src="post.better_featured_image.source_url"
        img-alt="Image"
        img-top
        tag="article"
        class="mb-2"
      >
        <b-card-text>
          <b-nav-item
            :to="{
              name: 'news-story',
              query: {
                id: post.id,
              },
            }"
          >
            <p class="postDate" v-html="post.date"></p>
            <p class="postTitle" v-html="post.title.rendered"></p>
          </b-nav-item>
          <span v-html="post.excerpt.rendered"></span>
        </b-card-text>
      </b-card>
    </b-col>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="itemList"
      align="center"
    ></b-pagination>
  </b-row>
</template>
<script>
export default {
  name: "newsStories",
  props: ["posts", "filter"],
  data() {
    return {
      perPage: 12,
      currentPage: 1,
    };
  },
  computed: {
    rows() {
      return this.posts.length;
    },
    filteredList: function(filt, list) {
      var p = this.posts;
      if (this.filter === "") {
        list = p.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
      } else {
        filt = p.filter((post) => {
          post.content.rendered.includes(this.filter);
        });
        list = filt.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
      }
      return list;
    },
  },
};
</script>

<style scoped>
p {
  max-height: 220px;
  overflow: hidden;
}
img.card-img-top {
  /* height: 170px; */
  height: auto;
}
p.postDate {
  color: #737373;
}
li {
  list-style-type: none;
}
.nav-link {
  padding: 0.5rem 0;
}
.postTitle {
  color: #0071b9;
}
.card {
  height: 500px;
}
.postCol {
  padding-bottom: 20px;
}
</style>
